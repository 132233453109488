<template>
  <div :class='{ "mx-0" : view !== "WALLET" }'>
    <div v-if="web3.account" class="d-flex flex-row justify-end align-center align-content-center">
      <div class="mx-4 py-1 d-flex justify-space-between align-center"
      >
        <v-icon size="16" left :color="view === 'WALLET' ? 't-bw-secondary' : 'secondary'">
          mdi-key-outline
        </v-icon>
        <p class="ma-0 pa-0" :class="[ { 't-bw-secondary--text':view === 'WALLET' }, {'secondary--text':view !== 'WALLET'} ]">
          {{ truncateAddress(web3.account) }}
        </p>
      </div>
      <v-btn small color="secondary" elevation="0"
             @click="clearSession"
      >
        <v-icon small color="t-bw-secondary"> mdi-logout </v-icon>
      </v-btn>
    </div>
    <v-btn v-else
           elevation="0"
           color="secondary"
           class="t-bw-secondary--text"
           @click="startSession"
    >
      {{ $t('tokens.buttons.login') }} Metamask
      <v-icon id='arrow' color="t-bw-secondary-color" right small>mdi-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Web3  from "web3";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LoginMetamask",

  props: {
    view: {
      type: String,
      required: true
    }
  },

  data:() => ({
    web3js: new Web3(Web3.givenProvider || "ws://localhost:8546")
  }),

  computed: {
    ...mapGetters({
      web3: 'web3/GET_WEB3'
    })
  },

  methods: {
    ...mapActions({
      login: 'web3/LOGIN',
      logout: 'web3/LOGOUT'
    }),
    async startSession() {
      await this.login().finally(async () => {
        switch (this.view) {
          case 'WALLET':
            await this.$root.$emit('initializeWalletData')
            break
          case 'TOKEN':
            await this.$root.$emit('initializeTokenData')
            break
          case 'INVENTORY':
            await this.$root.$emit('initializeInventoryData')
            break
        }
      })
    },
    clearSession() {
      this.logout()
      switch (this.view) {
        case 'WALLET':
          this.$emit('onLogoutMetamask')
          break
        case 'TOKEN':
          this.$root.$emit('closeTokenData')
          break
        case 'INVENTORY':
          this.$root.$emit('closeInventoryData')
          break
      }
    },
    truncateAddress(address) {
      if (!address) {
        return "";
      }
      return `${address.substr(0, 7)}...${address.substr(
          address.length - 7,
          address.length
      )}`;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
